import React, { useEffect } from "react"

import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import StyledButton from "../components/StyledButton"
import { makeStyles } from "@material-ui/core/styles"
import { BGBlurImage } from "../components/BGBlurImage"
import { Status } from "../redux/types"
import { LOGIN, WORKOUTS_HOME } from "../features/routing/Locations"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../features/login/loginSlice"
import { RootState } from "../redux/store"
import BackdropLoader from "../components/BackdropLoader"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  message: {
    width: "70%"
  },
  homeButton: {
    width: "260px",
    margin: `${theme.spacing(6)}px auto 0px auto`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px"
    }
  }
}))

const NotFoundPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const logoutResource = useSelector((state: RootState) => state.login)



  const data = useStaticQuery(graphql`
      query {
          bg : file(relativePath: { eq: "641-a-9278@3x.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 2048, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)
  useEffect(() => {
   dispatch(logout())
  }, [])

  useEffect(() => {
    if (logoutResource.status == Status.FINISHED && !logoutResource.data) {
      navigate(LOGIN, { replace: true })
    }
  }, [logoutResource])

  return <Layout>
    <SEO title="Logout"/>
    <BGBlurImage imageUrl={""} localImage={data.bg.childImageSharp.fluid}/>
    <div className={classes.root}>
      <Typography className={classes.message} variant={"h2"} align={"center"}>Hope you had a great workout :)</Typography>
      <StyledButton onClick={() => dispatch(logout())} className={classes.homeButton} text="Logout"/>
      <BackdropLoader show={logoutResource.status == Status.LOADING}/>
    </div>
  </Layout>
}

export default NotFoundPage
